import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Paper, TableContainer, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { services } from "../../../utils";
import { CheckCircleOutline } from "@mui/icons-material";
import PlansServicesHeader from "./PlansServicesHeader";
import PlansServicesFooter from "./PlansServicesFooter";
// import { amountInit, ILicense, IProject } from '../../../services/checkout';

const useStyles = makeStyles(() => ({
  service: { maxWidth: 600, marginRight: 15 },
  table: {
    "&.MuiPaper-root": { width: "auto", borderRadius: "10px 0 0 10px" },
  },
  tableRow: {
    width: 600,
    "& .MuiTableCell-root": {
      width: 130,
      border: 0,
      padding: 0,
      fontWeight: "bold",
      fontSize: 15,
      borderRight: "1px solid #a5a5a5",
      "&:last-child": { borderRight: 0 },
      "& .MuiSvgIcon-root": { color: "#5dc90a" },
    },
    "&:nth-of-type(odd)": { backgroundColor: "#e8e8e8" },
  },
  text: {
    width: 245,
    display: "block",
    padding: "0 2px 0 6px",
    fontWeight: 700,
  },
  first: { padding: "4px 0px !important" },
  check: { padding: "0 !important" },
}));

interface IProps {
  // selectService: (t: ILicense) => void
}

const PlansServices: FC<IProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.service} container>
      <PlansServicesHeader />

      <Grid container>
        <TableContainer className={classes.table} component={Paper}>
          <Table size="small">
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell align="left">
                  <Typography className={classes.text} variant="caption" noWrap>
                    {t("Almacenamiento")}
                  </Typography>
                </TableCell>
                {["5 GB", "10 GB", "20 GB"].map((i) => (
                  <TableCell key={i} className={classes.first} align="center">
                    {i}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow className={classes.tableRow}>
                <TableCell align="left">
                  <Typography className={classes.text} variant="caption" noWrap>
                    {t("Usuarios finales")}
                  </Typography>
                </TableCell>
                {["1 - 100", "101 - 1000", "1001 - 5000"].map((i) => (
                  <TableCell key={i} className={classes.first} align="center">
                    {i}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow className={classes.tableRow}>
                <TableCell align="left">
                  <Typography className={classes.text} variant="caption" noWrap>
                    {t("Usuarios administradores")}
                  </Typography>
                </TableCell>
                {["2", "5", "10"].map((i) => (
                  <TableCell key={i} className={classes.first} align="center">
                    {i}
                  </TableCell>
                ))}
              </TableRow>
              {services.map((service, i) => (
                <TableRow key={i} className={classes.tableRow}>
                  <TableCell align="left">
                    <Typography className={classes.text} variant="caption" noWrap>
                      {t(service)}
                    </Typography>
                  </TableCell>
                  {[0, 1, 2].map((i) => (
                    <TableCell key={i} className={classes.check} align="center">
                      <CheckCircleOutline fontSize="small" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <PlansServicesFooter />
    </Grid>
  );
};

export default PlansServices;
