import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Paper, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: { paddingLeft: 253 },
  header: {
    "&.MuiGrid-root": {
      alignItems: "flex-end",
      "& .MuiPaper-root": {
        width: "100%",
        color: "white",
        display: "flex",
        margin: "0 5px",
        padding: "5px 10px",
        boxShadow: "none",
        alignItems: "center",
        borderRadius: "10px 10px 0 0",
      },
    },
  },
  small: { "&.MuiPaper-root": { backgroundColor: "#6789e5", height: 40 } },
  medium: { "&.MuiPaper-root": { backgroundColor: "#4ccc6a", height: 50 } },
  large: {
    "&.MuiPaper-root": {
      backgroundColor: "#eabf0a",
      height: 60,
      margin: "0 0 0 5px !important",
    },
  },
}));

const PlansServicesHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.header} container item xs={4}>
        <Paper className={classes.small}>
          <Typography variant="subtitle2">{t("Plan pequeño")}</Typography>
        </Paper>
      </Grid>

      <Grid className={classes.header} container item xs={4}>
        <Paper className={classes.medium}>
          <Typography variant="subtitle2">{t("Plan mediano")}</Typography>
        </Paper>
      </Grid>

      <Grid className={classes.header} container item xs={4}>
        <Paper className={classes.large}>
          <Typography variant="subtitle2">{t("Plan grande")}</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PlansServicesHeader;
