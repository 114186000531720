export interface IAmount {
  uid: string;
  cop: number;
  usd: number;
  name: string;
}
export const amountInit = { uid: "", cop: 0, usd: 0, name: "" };

export interface ILicense {
  uid: string;
  name: string;
  amount: IAmount;
  amounts: IAmount[];
  min_users: number;
  max_users: number;
  color_one: string;
  color_two: string;
}
export const licenseInit: ILicense = {
  uid: "",
  name: "",
  amount: amountInit,
  amounts: [],
  min_users: 0,
  max_users: 0,
  color_one: "",
  color_two: "",
};

export interface IPlan {
  uid: string
  name: string
  amounts: IAmount[]
  color_one: string
  color_two: string
  min_users: number
  max_users: number
}
