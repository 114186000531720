import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import LayoutOlab from "../layouts/LayoutOlab";
import Background from "../components/Background/Background";
import PlansComponent from "../components/Plans";

const useStyles = makeStyles(() => ({
  root: { position: "relative" },
  page: {
    height: "calc(100vh - 60px)",
    marginTop: 60,
    justifyContent: "center",
    backgroundColor: "white",
  },
}));

const PlansPage = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container>
      <LayoutOlab />
      <Grid className={classes.page} container alignItems="center">
        <Background background="/img/background.svg" />
        <PlansComponent loading={false} />
      </Grid>
    </Grid>
  );
};

export default PlansPage;
