import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import { store } from "./store";
import { history } from "./configureStore";

const ReduxRoot = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router location={history.location} navigator={history}>
          <App />
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export default ReduxRoot;
