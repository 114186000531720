import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Avatar, Button, Grid, Paper, Theme, Typography } from "@mui/material";
import { additionalServices } from "../../../utils";

const Header = styled(Paper)({
  color: "white",
  width: "100%",
  height: 70,
  display: "flex",
  alignItems: "center",
  borderRadius: "10px 10px 0 0",
  justifyContent: "center",
  backgroundColor: "#481870",
});
const Image = styled(Avatar)({
  right: 0,
  width: 111,
  height: "auto",
  bottom: 0,
  position: "absolute",
  borderRadius: 0,
});
const Accept = styled(Button)({
  color: "white",
  borderRadius: "0 0 10px 10px",
  backgroundColor: "#481870",
  "&:hover": { backgroundColor: "white", color: "#481870" },
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 280,
    [theme.breakpoints.down("xs")]: { maxWidth: "100%" },
  },
  content: {
    height: 215,
    padding: "15px 10px",
    position: "relative",
    backgroundColor: "white",
    "& .MuiTypography-root": { width: "100%", fontWeight: 500 },
  },
}));

interface IProps {
  openView: () => void;
}

const AdditionalServices: FC<IProps> = ({ openView }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.root} container>
      <Header>
        <Typography align="center" variant="body1" noWrap>
          {t("Servicios adicionales (opcional)")}
        </Typography>
      </Header>
      <Grid className={classes.content} container alignItems="flex-start">
        <Grid container>
          {additionalServices.map((name, i) => (
            <Typography key={i} variant="body2" display="block" noWrap>
              {t(name)}
            </Typography>
          ))}
        </Grid>
        <Image src="/gif/payment.gif" alt="" />
      </Grid>
      <Accept variant="contained" onClick={openView} fullWidth>
        {t("Ver")}
      </Accept>
    </Grid>
  );
};

export default AdditionalServices;
