import { combineReducers } from "redux";
import { payment } from "./modules/payment/reducer";
import { State } from "./types";

const combinedReducer = combineReducers<State>({
  payment,
});

export type RootState = ReturnType<typeof combinedReducer>;

export default combinedReducer;
