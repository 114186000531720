import PlansPage from "./pages/PlansPage";

const routes = [
  {
    path: "/*",
    icon: "",
    menu: true,
    name: "Planes",
    image: "",
    permission: "",
    Component: PlansPage,
  },
];

export default routes;
