import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
// import { IUser } from "../../services/user";
// import { IEntity } from "../../services/entity";
// import {
//   ILicense,
//   licenseInit,
//   IProject,
//   amountInit,
//   IAmount,
// } from "../../services/checkout";
// import ContactUs from "./ContactUs";
// import Additional from "./Additional";
import { licenseInit } from "../../services/payment";
import PayStepOne from "./Services";
// import PayStepTwo from "./License/PayStepTwo";
// import PayStepSix from "./PayStepSix";
// import PayStepFour from "./PayStepFour";
// import PayStepFive from "./PayStepFive";
// import PayStepThree from "./PayStepThree";
// import PayStepOneMobile from "./Service/PayStepOneMobile";

const useStyles = makeStyles(() => ({
  checkout: {
    position: "relative",
    height: "fit-content",
    maxHeight: "100%",
    overflow: "auto",
  },
}));

interface IProps {
  loading: boolean;
  //   projects: IProject[];
  //   createUserEntity: (
  //     user: IUser,
  //     entity: IEntity,
  //     password: string,
  //     callback: () => void
  //   ) => void;
}

const PlansComponent: FC<IProps> = ({
  // loading,
  // projects,
  // createUserEntity
}) => {
  const classes = useStyles();
  const [step, setStep] = React.useState("SERVICE");
  const [info, setInfo] = React.useState({
    name: "",
    email: "",
    phone: "",
    entity: "",
  });
  const [license, setLicense] = React.useState(licenseInit);
  const [currency, setCurrency] = React.useState<"usd" | "cop">("usd");

  //   const selectService = (license: ILicense) => {
  //     setStep("LICENSE");
  //     setLicense({ ...license, amount: amountInit });
  //   };
  const deselectService = () => {
    setStep("SERVICE");
    // setLicense(licenseInit);
  };

  //   const selectLicense = (amount: IAmount) => {
  //     setStep("STRIPE");
  //     setLicense({ ...license, amount });
  //   };
  const deselectLicense = () => {
    setStep("LICENSE");
    // setLicense({ ...license, amount: amountInit });
  };

  const openServiceOptional = () => setStep("OPTIONAL");
  const closeOptional = () => setStep("SERVICE");

  const openContactUs = () => setStep("CONTACT_US");
  const closeContactUs = () => setStep("SERVICE");

  const openStepSix = () => setStep("STEP_SIX");
  const openStepFour = () => setStep("STEP_FOUR");
  const openStepFive = () => setStep("STEP_FIVE");

  return (
    <Grid className={classes.checkout} container justifyContent="center">
      {step === "SERVICE" && !license.name && (
        <>
          <PayStepOne
            // projects={projects}
            // selectService={selectService}
            openContactUs={openContactUs}
            openAdditionalServices={openServiceOptional}
          />
          {/* <PayStepOneMobile
            projects={projects}
            selectService={selectService}
            openContactUs={openContactUs}
            openServiceOptional={openServiceOptional}
          /> */}
        </>
      )}
      {/* {step === "LICENSE" && !!license.name && !license.amount.uid && (
        <PayStepTwo
          license={license}
          currency={currency}
          setCurrency={setCurrency}
          selectLicense={selectLicense}
          deselectService={deselectService}
        />
      )} */}
      {/* {step === "STRIPE" && !!license.name && !!license.amount.uid && (
        <PayStepThree
          license={license}
          currency={currency}
          updateInfo={setInfo}
          prevSection={deselectLicense}
          nextSection={openStepFour}
          setCurrency={setCurrency}
        />
      )} */}
      {/* {step === "STEP_FOUR" && (
        <PayStepFour license={license} nextStep={openStepFive} />
      )} */}
      {/* {step === "STEP_FIVE" && (
        <PayStepFive
          info={info}
          loading={loading}
          nextStep={openStepSix}
          createUserEntity={createUserEntity}
        />
      )} */}
      {/* {step === "STEP_SIX" && <PayStepSix />} */}

      {/* {step === "OPTIONAL" && (
        <Additional
          closeOptional={closeOptional}
          openContactUs={openContactUs}
        />
      )} */}
      {/* {step === "CONTACT_US" && <ContactUs closeContactUs={closeContactUs} />} */}
    </Grid>
  );
};

export default PlansComponent;
