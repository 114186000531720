import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Button, ButtonGroup, Grid } from "@mui/material";
import { plans } from "../../../utils";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 253,
    '& .MuiButtonGroup-root': {
      borderRadius: "0 0 10px 10px",
      '& .MuiButton-root': {
        backgroundColor: "#ff6300",
        color: "white",
        "& .MuiButton-label": {
          display: "flow-root",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
        "&:hover": { backgroundColor: "white", color: "#ff6300" },
        "&:first-child": { borderRadius: "0 0 0 10px" },
        "&:last-child": { borderRadius: "0 0 10px 0" },
      },
    },
  },
}));

const PlansServicesFooter = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.root} container>
      <ButtonGroup variant="contained" fullWidth>
        {plans.map((plan) => (
          <Button
            key={plan.uid}
            // onClick={() => selectService({ ...plan, amount: amountInit })}
          >
            {t("Ver")}
          </Button>
        ))}
      </ButtonGroup>
    </Grid>
  );
};

export default PlansServicesFooter;
