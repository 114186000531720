import React from "react";
// import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, Grid, Theme } from "@mui/material";
// import * as actions from "../store/actions";
// import Languaje from "./Languaje";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    minHeight: "60px",
    [theme.breakpoints.down("xs")]: { boxShadow: "none" },
  },
  toolbar: {
    minHeight: "60px !important",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: { padding: "0 10px" },
  },
  img: { maxHeight: 40 },
}));

const LayoutOlab = () => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  // const changeLanguajeCode = (code: string) => dispatch(actions.changeLanguajeCode(code));

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center">
          <Grid item container xs={6}>
            <img className={classes.img} src="/img/logo.svg" alt="" />
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            xs={6}
          >
            {/* <Languaje changeLanguajeCode={changeLanguajeCode} /> */}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default LayoutOlab;
