import { IPlan } from "../services/payment";

export const plans: IPlan[] = [
  {
    uid: "prod_KzhCS2LZJF4Yu0",
    name: "Plan pequeño",
    amounts: [
      {
        uid: "price_1KJhnoJT4R6W55saMqleUZVr", 
        cop: 1950000, 
        usd: 1000, 
        name: "Año"
      },
      {
        uid: "price_1KJhnoJT4R6W55saH90HpfBL", 
        cop: 195000, 
        usd: 100, 
        name: "Mes"
      },
    ],
    color_one: "#6789E5",
    color_two: "#5B79E8",
    min_users: 1,
    max_users: 100,
  },
  {
    uid: "prod_L5QbPvgL720B16",
    name: "Plan mediano",
    amounts: [
      {
        uid: "price_1KPFkmJT4R6W55saHYkqJvlu", 
        cop: 9500000,
        usd: 2500, 
        name: "Año"
      },
      {
        uid: "price_1KPFkmJT4R6W55saj6GZm9Wr", 
        cop: 950000, 
        usd: 250, 
        name: "Mes"
      },
    ],
    color_one: "#4CCC6A",
    color_two: "#34C65D",
    min_users: 101,
    max_users: 1000,
  },
  {
    uid: "prod_L5QvRhJ43IijiT",
    name: "Plan grande",
    amounts: [
      {
        uid: "price_1KPG3JJT4R6W55sa4dccOdSS", 
        cop: 19000000,
        usd: 5000,
        name: "Año"
      },
      {
        uid: "price_1KPG3JJT4R6W55saxWWv1OKu", 
        cop: 1900000,
        usd: 500,
        name: "Mes"
      },
    ],
    min_users: 1001,
    max_users: 5000,
    color_one: "#EABF0A",
    color_two: "#E2B700",
  }
];

export const services = [
  "Plataforma web personalizable",
  "Aplicación móvil personalizable",
  "Sistema de gestión de aprendizaje (LMS)",
  "Funciones de seguimiento y evaluación",
  "Soporte técnico virtual",
];

export const additionalServices = [
  "Asistencia en coordinación de proyectos",
  "Diseño y animación de avatares y más",
  "Reportes de impacto específicos",
  "Sistema de gestión de proyectos",
  "Voluntariado corporativo",
  "Asistencia pedagógica",
  "Diseño de contenidos",
  "Doblaje de voz",
  "Otros",
];
