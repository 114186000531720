import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff6315",
    },
    // secondary: {
    //   main: "#f96332",
    // },
  },
});

export default theme;
