import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Grid } from "@mui/material";

const useStyles = makeStyles(() => ({
  background: {
    top: 0,
    bottom: 0,
    position: "absolute",
    overflow: "hidden",
    "& .MuiAvatar-root": {
      width: "100% ",
      height: "100% ",
      borderRadius: "0 ",
    },
  },
}));

interface IProps {
  background: string;
}

const Background: FC<IProps> = ({ background }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.background} container justifyContent="center">
      <Avatar src={background} alt="" />
    </Grid>
  );
};

export default Background;
