import { PaymentState, PaymentActionType } from "./types";
import * as types from "./types";

export const PAYMENT_INITIAL_STATE: PaymentState = {
  loading: false,
  // licenses: [],
  // projects: projectsInit,
  clientSecret: "",
  licensesLoading: false,
};

export const payment = (prevState = PAYMENT_INITIAL_STATE, action: PaymentActionType) => {
  switch (action.type) {
    case types.LOADING:
      return { ...prevState, loading: action.payload };
    case types.CLIENT_SECRET:
      return { ...prevState, clientSecret: action.payload };
    case types.LICENSES_LOADING:
      return { ...prevState, licensesLoading: action.payload };

    default:
      return prevState;
  }
};
