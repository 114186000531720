import React from "react";
import { Routes, Route } from "react-router-dom";
import routes from "./routes";

const App = () => {
  return (
    <Routes>
      {routes.map((route, key) => (
        <Route 
          key={key}
          path={route.path} 
          element={<route.Component />} 
        />
      ))}
    </Routes>
  );
};

export default App;
