import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Paper, TableContainer, Theme } from "@mui/material";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import { Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { services } from "../../../utils";

const useStyles = makeStyles((theme: Theme) => ({
  custom: {
    maxWidth: 130,
    marginRight: 15,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      margin: 0,
      marginBottom: 15,
    },
  },
  header: {
    "&.MuiPaper-root": {
      color: "white",
      width: "100%",
      height: 60,
      display: "flex",
      padding: "5px 10px",
      boxShadow: "none",
      alignItems: "center",
      borderRadius: "10px 10px 0 0",
      backgroundColor: "#ff6315",
    },
  },
  table: {
    "&.MuiPaper-root": {
      width: "auto",
      borderRadius: 0,
      [theme.breakpoints.down("xs")]: { width: "100%" },
    },
  },
  check: { width: 140, padding: "0 !important", fontSize: 15 },
  first: { width: 140, padding: "4px 0 !important", fontSize: 15 },
  color: {
    "& .MuiTableCell-root": {
      fontWeight: "bold",
      border: 0,
      borderRight: "1px solid #a5a5a5",
      "&:last-child": { borderRight: 0 },
      "& .MuiSvgIcon-root": { color: "#5dc90a" },
    },
    "&:nth-of-type(odd)": { backgroundColor: "#e8e8e8" },
  },
  btn: {
    "&.MuiButton-root": {
      color: "white",
      padding: "6px 10px",
      borderRadius: "0 0 10px 10px",
      justifyContent: "start",
      backgroundColor: "#ff6300",
      "& .MuiButton-label": {
        display: "flow-root",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      "&:hover": { backgroundColor: "white", color: "#ff6300" },
    },
  },
  link: { width: "100%", color: "initial", textDecoration: "none" },
}));

const CustomizedServices = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.custom} container>
      <Paper className={classes.header}>
        <Typography variant="subtitle2">{t("Plan personalizado")}</Typography>
      </Paper>

      <TableContainer className={classes.table} component={Paper}>
        <Table size="small">
          <TableBody>
            {["+20 GB", "+5001", "+11"].map((name) => (
              <TableRow key={name} className={classes.color}>
                <TableCell className={classes.first} align="center">
                  {name}
                </TableCell>
              </TableRow>
            ))}
            {services.map((service) => (
              <TableRow key={service} className={classes.color}>
                <TableCell className={classes.check} align="center">
                  <CheckCircleOutline fontSize="small" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <a className={classes.link} href="https://o-lab.app/es/pagos-colombia/">
        <Button className={classes.btn} variant="contained" fullWidth>
          {t("Contáctanos")}
        </Button>
      </a>
    </Grid>
  );
};

export default CustomizedServices;
