import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Theme } from "@mui/material";
import PlansServices from "./PlansServices";
import CustomizedServices from "./CustomizedServices";
import AdditionalServices from "./AdditionalServices";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "15px 5px",
    [theme.breakpoints.down("xs")]: { display: "none" },
  },
  content: { marginBottom: 30 },
  button: { "&.MuiButton-root": { width: 350, borderRadius: 25 } },
  link: { textDecoration: "none", color: "initial" },
}));

interface IProps {
  // selectService: (value: ILicense) => void
  openContactUs: () => void;
  openAdditionalServices: () => void;
}

const Services: FC<IProps> = ({ openAdditionalServices }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid className={classes.content} container justifyContent="center">
        <PlansServices />
        <CustomizedServices />
        <AdditionalServices openView={openAdditionalServices} />
      </Grid>

      <a
        className={classes.link}
        href="https://www.originlearningfund.org/o-lab"
      >
        <Button className={classes.button} variant="contained" color="inherit">
          {t("Volver")}
        </Button>
      </a>
    </Grid>
  );
};

export default Services;
